import axios from '@/common/axios'
import qs from 'qs'

export function addAnalysisReport (data) {
  return axios({
    method: 'post',
    url: '/technology/analysis/add',
    data: qs.stringify(data)
  })
}

export function deleteAnalysisReport (id) {
  return axios({
    method: 'delete',
    url: '/technology/analysis/delete/' + id
  })
}

export function updateAnalysisReport (data) {
  return axios({
    method: 'put',
    url: '/technology/analysis/update',
    data: qs.stringify(data)
  })
}

export function selectAnalysisReportInfo (id) {
  return axios({
    method: 'get',
    url: '/technology/analysis/info/' + id
  })
}

export function selectAnalysisReportList (query) {
  return axios({
    method: 'get',
    url: '/technology/analysis/list',
    params: query
  })
}
